import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "name",
      model: "name",
      placeholder: "name",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("nameMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "url",
      model: "url",
      placeholder: "url",
      max: 255,
      validator: [
        validators.string.locale({
          textTooBig: i18n.t("urlMustBeLess{1}")
        }),
      ]
    },
    {
      type: "input",
      inputType: "text",
      label: "shortDescription",
      model: "shortDescription",
      required: false,
      placeholder: "shortDescription",
    },
    {
      type: "input",
      inputType: "text",
      label: "description",
      model: "description",
      required: false,
      placeholder: "description",
    },
    {
      type: "select",
      label: "status",
      model: "status",
      required: false,
      selectOptions: {
        noneSelectedText: i18n.t("chooseStatus"),
      },
      values: [
        { name: i18n.t("Active"), id: 1 },
        { name: i18n.t("Hidden"), id: 0 },
      ],
    },
  ]
};
