<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('productsList') }}</h2>
      <grid-view-table
        :id="'product'"
        :columns="columns"
        :extended-filter-schema="extendedFilterSchema"
        :path="'product'"
        :add-route="'AddProduct'"
        :update-route="'UpdateProduct'"
        :add-text="$t('addProduct')"
        :extra-params="'&join=tagItems&join=tagItems.tag&join=tagPriceItems'"
        :default-order="'createdAt,DESC'"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/products';
  import { schema as extendedFilterSchema } from './filter-forms-schemas/product';

  export default {
    name: 'MailGroupsList',
    components: {
      GridViewTable,
    },
    data: () => {
      return {
        columns,
        extendedFilterSchema,
      }
    },
  }
</script>
