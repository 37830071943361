import moment from 'moment';
import { actions } from "../../../components/grid-views/standart-actions-without-view";
import { statusActions } from "../../../components/grid-views/status-actions";
import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: 'producer',
    field: 'tagItems.value',
    formatRowValue: true,
    formatFn: (value) => {
      const producer =  value.tagItems.find(tagItem => tagItem.tag && tagItem.tag.token === 'producer');
      if (producer) {
        return producer.value;
      }
      return '';
    },
    width: '13%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'name',
    field: 'name',
    width: '23%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'sku',
    field: 'tagPriceItems.sku',
    formatRowValue: true,
    formatFn: (value) => {
      return value.tagPriceItems
        .filter(tagPriceItem => tagPriceItem.sku)
        .map(tagPriceItem => tagPriceItem.sku)
        .join(', ');
    },
    width: '20%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'date',
    field: 'updatedAt',
    width: '14%',
    formatRowValue: true,
    formatFn: (value) => {
      return moment(value.updatedAt).format('DD.MM.YYYY HH:mm');
    },
    filterOptions: {
      enabled: true,
      date: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'status',
    field: 'status',
    width: '10%',
    formatFn: (value) => {
      if (value === 1) {
        return i18n.t('Active');
      }
      return i18n.t('Hidden');
    },
    filterOptions: {
      enabled: true,
      strict: true,
      placeholder: ' ',
      filterDropdownItems: [
        {
          text: i18n.t('Active'),
          value: 1,
        },
        {
          text: i18n.t('Hidden'),
          value: 0,
        },
      ],
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      ...statusActions,
      ...actions,
    ]
  },
]
